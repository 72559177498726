@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: "Roboto", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    90deg,
    hsla(153, 9%, 19%, 1) 0%,
    hsla(153, 9%, 19%, 1) 100%
  );
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: var(--clr-bg);
  background-image: url(/static/media/pre.7d388ee8.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

.sticky {
  transition: all 0.5s ease-out 0s !important;
  box-shadow: 0px 2px 2px 0px var(--clr-border) !important;
  -webkit-backdrop-filter: blur(250px) !important;
          backdrop-filter: blur(250px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.5s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: var(--clr-txt) !important;
  height: 4px !important;
  width: 27px !important;
  outline: none !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  box-shadow: none !important;
  outline: none !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 768px) {
  .navbar {
    font-size: 1.2rem !important;
    transition: all 0.5s ease-out 0s !important;
    box-shadow: 0px 2px 2px 0px var(--clr-border) !important;

    -webkit-backdrop-filter: blur(250px) !important;

            backdrop-filter: blur(250px) !important;
  }
}
.navbar-brand {
  color: var(--clr-txt) !important;
}

.logo {
  height: 2.5em;
  width: 3em;
}

.navbar-nav .nav-link {
  color: var(--clr-txt) !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.nav-link {
  padding: 0.8rem 1rem !important;
}

.theme-switch {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
  .logo {
    height: 1.9em !important;
    width: 2.5em !important;
  }
  .theme-switch {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background-color: var(--clr-primary);
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* theme Switch */
#toggle {
  width: 50px;
  display: flex;
  padding: 5px;
  background-color: #202523;
  display: block;
  border-radius: 1000px;
  cursor: pointer;
  box-shadow: 0px 5px 20px -10px var(--shadow);
  transition: background-color 0.3s ease-in;
}
#toggle .toggle-inner {
  width: 20px;
  height: 15px;
  background-color: var(--clr-bg);
  border-radius: 1000px;
  transition: margin-left 0.3s ease-in, background-color 0.3s ease-in;
}
#toggle .toggle-inner.toggle-active {
  margin-left: 20px;
}
.dark-mode #toggle {
  background-color: var(--clr-txt);
}
.dark-mode #toggle .toggle-inner {
  background-color: #202523;
}

.scroll-up {
  position: fixed;
  bottom: 75px;
  right: 30px;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  z-index: 100;
  color: var(--clr-txt);
  background: transparent !important;
  transition: 0.5s;
}

.scroll-up:hover {
  color: var(--clr-primary);
}

@media (max-width: 570px) {
  .scroll-up {
    height: 40px;
    width: 40px;
  }
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.home-header {
  padding-top: 80px !important;
}

.primary-header {
  color: var(--clr-primary) !important;
}

.home-section {
  position: relative;
  z-index: 1;
  background-color: var(--clr-bg);
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}

.home-content {
  padding: 5rem 2rem !important;
  color: var(--clr-txt);
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 3em !important;
  padding-left: 45px !important;
}

.main-name {
  color: var(--clr-primary) !important;
}

.Typewriter__wrapper {
  font-size: 2em !important;
  color: var(--clr-primary) !important;
  font-weight: 600 !important;
  padding-left: 25px;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: var(--clr-primary) !important;
}

@media (max-width: 991px) {
  .heading {
    font-size: 2.3em !important;
    padding-left: 0px !important;
    text-align: left !important;
  }

  .heading-name {
    font-size: 4em !important;
    padding-left: 0px !important;
    text-align: left !important;
  }
  .type{
    padding-left: 0px;
  }
  .Typewriter__wrapper {
    font-size: 1.3em !important;
    padding: 0px !important;
  }
  .Typewriter__cursor {
    font-size: 1.5em !important;
  }
}
@media (max-width: 767px) {
  .heading {
    font-size: 2.2em !important;
    padding-left: 20px !important;
    text-align: left !important;
  }

  .heading-name {
    font-size: 3em !important;
    padding-left: 20px !important;
    text-align: left !important;
  }
  .type{
    text-align: left !important;
  }
  .Typewriter__wrapper {
    text-align: center !important;
    font-size: 1.2em !important;
    font-weight: 400 !important;
    position: absolute;
  }

  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 0 !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  background-color: var(--clr-bg-alt);
}

.home-about-description {
  color: var(--clr-txt) !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center !important;
}

.custom-list-group {
  border: none;
  border-radius: 0;
  overflow: hidden;
  background-color: transparent;
}

.custom-list-group-item {
  padding: 15px 20px;
  background-color: transparent;
  border: none;
  transition: background-color 0.3s, color 0.3s;
}

.custom-list-group-item:hover {
  background-color: #ffffff76;
  color: var(--clr-primary);
}

.custom-list-group-item.active {
  background-color: transparent;
  color: white;
  font-weight: bold;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: var(--clr-txt) !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: var(--clr-bg) !important;
  box-shadow: var(--shadow) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--clr-primary);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--clr-primary);
}

.home-social-icons:hover {
  color: var(--clr-primary);
  box-shadow: 0 0 5px var(--clr-primary);
  text-shadow: 0 0 2px var(--clr-primary);
}

.social-icons {
  display: inline-block !important;
  padding-right: 10px;
  padding-left: 10px;
  color: var(--clr-primary);
}

.icon-colour {
  color: var(--clr-primary) !important;
}

.app {
  font-family: "Roboto", sans-serif;
  text-align: center;
  line-height: 1.5;
  color: var(--clr-txt);
  background-color: var(--clr-bg);
}

.light {
  --clr-bg: #ffffff;
  --clr-bg-alt: #f6f4f4;

  --clr-txt: #000000ca;
  --clr-primary: #7a030f;

  --clr-border: #919090;

  --clr-nav: #fefefe;

  --shadow: rgb(100, 100, 111) 0px 7px 29px 0px;
}

.dark {
  --clr-bg: #202523;
  --clr-bg-alt: #1d2120;

  --clr-txt: #eeeeee;
  --clr-primary: #7a030f;
  --clr-border: #eeeeee7c;
  --clr-nav: #272f2b;
  --shadow: rgba(0, 0, 0, 0.558) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #7a030f;
}

::-webkit-scrollbar-thumb {
  background: #ca626c;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3c010c;
  border-radius: 12px;
}

